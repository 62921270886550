@import "node_modules/bulma/sass/utilities/initial-variables";

@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap&subset=latin-ext');

$family-serif: 'Open Sans', sans-serif;
@import "node_modules/bulma/bulma";

body {
    background-color: whitesmoke;
}

$navHeight: 60px;
$navPadding: 10px;

nav {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: $navHeight;
    background-color: #d5ac00;
    display: flex;
    flex-direction: column;
    padding: $navPadding;
    align-items: center;
    justify-content: flex-end;

    .user_name {
        color: azure;
    }
    .user_role {
        &::before {
            content: "<";
        }
        &::after {
            content: ">";
        }
        color: azure;
        font-size: 10px;
    }
}

#body {
    position: absolute;
    top: $navHeight+2*$navPadding;
    left: 0;
    right: 0;
    bottom: 0;

    > div {
        display: flex;
        align-items: center;
        flex-direction: column;
        height: 100%;
    }

    button:not(.button) {
        font-size: 1.05em;
        margin: 10px;
        padding: $navPadding;
        color: antiquewhite;
        border: none;
        background-color: #d5ac00;
        width: 200px;
        &.logout {
            background-color: red;
        }
    }
}